<template>
  <div class="detail padding20">
    <el-row class="breadcrumb" align="middle" type="flex">
    </el-row>
    <div class="detail-title">
      <div class="title-header">
        <p class="header-icon"><i class="el-icon-document"></i>  <span>{{myApplyDetail.shareName}}</span></p>
        <p v-if="myApplyDetail.state!==1&&myApplyDetail.state!==2"> 
          <el-button type="success" size="medium" @click="changeType(myApplyDetail.id,1)">通过</el-button>
          <el-button type="danger" size="medium" @click="changeType(myApplyDetail.id,2)">拒绝</el-button>
        </p>
        <p v-else class="title-state"><span>状态:</span><span :style="myApplyDetail.state|applyStateColor">{{myApplyDetail.state|applyState}}</span></p>
      </div>
      <div class="datail-bottom">
        <div class="title-text">
          <div class="title-date">
            <span>申请时间:  </span>
            <span>{{myApplyDetail.createDate|date}}</span>
          </div>
          <div class="title-man">
            <span>申请原因:  </span>
            <span>{{myApplyDetail.description}}</span>
          </div>
        </div>
      </div>
    </div>
     <!-- 文件资源 -->
    <ShowFileList :shareInfo="myApplyDetail" :nowrap="true" :title="'申请文件列表'" />
    <div class="detail-back"><el-button type='primary' @click="back">返回上级</el-button></div>
  </div>
</template>
<script>
import { requestAPI ,shareAPI} from 'liankong-ui-api' 
import ShowFileList from '@/components/ShowFileList.vue'
export default {
  data() {
    return {
      viewerKey:'sender',
      myApplyDetail:{},
      verker:'shareDocList',
    };
  },
  components: {
    ShowFileList
  },
  computed: {
  },
  methods: {
    backFile(){
      this.$store.dispatch('doc/backFolder', {
        vkey: this.verker
      })
    },
    changeType(id,state) {
        const loading = this.$loading({
        lock: true,
        text: '正在审核中请等待。。。',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      requestAPI.apply(id,{
        state
      }).then(({code,data,message})=>{
          if(code == 0){
            loading.close();
            this.$message.success('审核成功')
            this.back()
          } else {
            loading.close();
            this.$message.error(message)
          }
      })
    },
    back() {
      this.$store.commit('request/updateState',{
        vkey:'receiver',
        stateValue:'阅读授权'
      })
      this.$router.replace({path:'/confer'})
    },
  },
  mounted() {
    this.myApplyDetail = this.$mapData.get('MyConferDateil')
    this.$store.commit('doc/createViewer', {
      viewerKey: this.verker,
      defaultFiles: this.myApplyDetail.resources
    })
  },
  created() {
  },
  destroyed() {
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/index";
.detail {
  padding: 0 20px;
  .detail-title {
    width: 100%;
    min-height: 150px;
    background: #f9fcff;
    border: 1px solid #e7eef4;
    justify-content: space-around;
    padding: 0px 27px;
    box-sizing: border-box;
    .title-header {
      display: flex;
      justify-content: space-between;
      .header-icon {
        font-size: 20px;
        color: #009DD9;
        .el-icon-document {
          font-size: 28px;
          margin-right: 5px;
          color: #ffaf1d;
        }
      }
      .title-state {
        display: flex;
        align-items: center;
        color: black;
      }
    }
    .datail-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title-text {
        display: flex;
        box-sizing: border-box;
        //   margin-top: 30px;
        font-size: 14px;
        .title-date {
          margin-right: 50px;
          & > span:nth-child(2) {
            color: #000;
          }
        }
        .title-man {
          & > span:nth-child(2) {
            color: #000;
          }
        }
      }
    }
  }
  .detail-read {
    width: 100%;
    margin-top: 32px;
    .title-box {
      border-bottom: 1px solid #f0f1f5;
      padding-bottom: 16px;
    }
    .read-title {
      display: flex;
      align-items: center;
      box-sizing: border-box;
      span {
        display: inline-block;
        height: 20px;
        width: 5px;
        background: @Brand;
        border-radius: 50px;
        margin-right: 10px;
      }
    }
    .add {
      font-size: @h3;
      span {
        border-radius: 50px;
        background: #59b880;
        padding: 2px;
        i {
          color: #fff;
        }
      }
    }
    .read-bottom-box {
      margin-top: 30px;
      display: flex;
      justify-content: space-between;
    }
    .read-context {
      width: 100%;
      table {
        padding-bottom: 10px;
      }

      .title-link {
        white-space: nowrap;
        position: relative;
        font-size: @h3;
        cursor: pointer;
      }
      & /deep/ .el-table td {
        border: none;
      }
      & /deep/ .el-table::before {
        display: none;
      }
    }
  }
  .detail-back {
    width: 100%;
    display:flex;
    justify-content: center;
  }
}

.breadcrumb {
  font-size: @h3;
  padding: 30px 0px;
  & /deep/ .el-breadcrumb {
    font-size: @h3;
  }
}
</style>